import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timeline-item" }
const _hoisted_2 = { class: "timeline-icon symbol symbol-circle symbol-40px" }
const _hoisted_3 = { class: "symbol-label bg-light" }
const _hoisted_4 = { class: "svg-icon svg-icon-2 svg-icon-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "timeline-line w-40px" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/coding/cod008.svg" })
        ])
      ])
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"timeline-content mb-10 mt-n1\"><div class=\"mb-5 pe-3\"><a href=\"#\" class=\"fs-5 fw-bold text-gray-800 text-hover-primary mb-2\">3 New Incoming Project Files:</a><div class=\"d-flex align-items-center mt-1 fs-6\"><div class=\"text-muted me-2 fs-7\">Sent at 10:30 PM by</div><div class=\"symbol symbol-circle symbol-25px\" data-bs-toggle=\"tooltip\" data-bs-boundary=\"window\" data-bs-placement=\"top\" title=\"Jan Hummer\"><img src=\"media/avatars/300-23.jpg\" alt=\"img\"></div></div></div><div class=\"overflow-auto pb-5\"><div class=\"d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5\"><div class=\"d-flex flex-aligns-center pe-10 pe-lg-20\"><img alt=\"\" class=\"w-30px me-3\" src=\"media/svg/files/pdf.svg\"><div class=\"ms-1 fw-bold\"><a href=\"#\" class=\"fs-6 text-hover-primary fw-bolder\">Finance KPI App Guidelines</a><div class=\"text-gray-400\">1.9mb</div></div></div><div class=\"d-flex flex-aligns-center pe-10 pe-lg-20\"><img alt=\"\" class=\"w-30px me-3\" src=\"media/svg/files/doc.svg\"><div class=\"ms-1 fw-bold\"><a href=\"#\" class=\"fs-6 text-hover-primary fw-bolder\">Client UAT Testing Results</a><div class=\"text-gray-400\">18kb</div></div></div><div class=\"d-flex flex-aligns-center\"><img alt=\"\" class=\"w-30px me-3\" src=\"media/svg/files/css.svg\"><div class=\"ms-1 fw-bold\"><a href=\"#\" class=\"fs-6 text-hover-primary fw-bolder\">Finance Reports</a><div class=\"text-gray-400\">20mb</div></div></div></div></div></div>", 1))
  ]))
}