import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timeline-item" }
const _hoisted_2 = { class: "timeline-icon symbol symbol-circle symbol-40px" }
const _hoisted_3 = { class: "symbol-label bg-light" }
const _hoisted_4 = { class: "svg-icon svg-icon-2 svg-icon-gray-500" }
const _hoisted_5 = { class: "timeline-content mb-10 mt-n1" }
const _hoisted_6 = { class: "overflow-auto pb-5" }
const _hoisted_7 = { class: "notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6" }
const _hoisted_8 = { class: "svg-icon svg-icon-2tx svg-icon-primary me-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "timeline-line w-40px" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"pe-3 mb-5\"><div class=\"fs-5 fw-bold mb-2\">You have received a new order:</div><div class=\"d-flex align-items-center mt-1 fs-6\"><div class=\"text-muted me-2 fs-7\">Placed at 5:05 AM by</div><div class=\"symbol symbol-circle symbol-25px\" data-bs-toggle=\"tooltip\" data-bs-boundary=\"window\" data-bs-placement=\"top\" title=\"Robert Rich\"><img src=\"media/avatars/300-4.jpg\" alt=\"img\"></div></div></div>", 1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/coding/cod004.svg" })
          ]),
          _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap\"><div class=\"mb-3 mb-md-0 fw-bold\"><h4 class=\"text-gray-800 fw-bolder\"> Database Backup Process Completed! </h4><div class=\"fs-6 text-gray-600 pe-7\"> Login into Metronic Admin Dashboard to make sure the data integrity is OK </div></div><a href=\"#\" class=\"btn btn-primary px-6 align-self-center text-nowrap\"> Proceed </a></div>", 1))
        ])
      ])
    ])
  ]))
}